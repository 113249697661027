body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --black: #171321;
  --white: #f7f8fa;
  --lightgrey: #f3f3f3;
  --mono: 'Arial';
  --sans: Arial;
  --twd-blue: #00adef;
  --twd_aqua: rgb(0, 173, 239);
  --twd_aqua_pale_1: rgb(183, 222, 250);
  --twd_aqua_pale_2: rgb(236, 247, 254);
  --twd_aqua_pale_3: rgb(245, 251, 255);
  --twd_aqua_darker: rgb(0, 146, 201);
  --twd_navy: rgb(0, 48, 107);
  --twd_navy_darker: rgb(0, 35, 78);
  --twd_sunglow: rgb(255, 200, 87);
  --twd_red: rgb(176, 59, 56);
  --twd_red_darker: rgb(191, 23, 22);
  --twd_white: rgb(255, 255, 255);
  --twd_grey: rgb(235, 233, 233);
  --twd_web_grey: rgb(204, 204, 204);
  --twd_border_grey: rgb(221, 221, 221);
  --twd_web_black: rgb(51, 51, 51);
  --twd_signal: rgb(221, 28, 26);
  --twd_signal_1: rgb(255, 110, 103);
  --twd_fig_green: rgb(120, 200, 65);
}
